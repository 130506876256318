const AUTH_MODULE = {
  BE_CRITICALS: [
    "role-cog-be-development",
    "role-cog-be-operations",
    "role-cog-bg-operations",
    "role-sg-connect-mutation_opsgenie_workflow_alert_ack",
    "role-sg-sala-query_opsgenie_alerts",
    "role-sg-sala-query_servicenow_accounts",
    "role-sg-sala-query_servicenow_configuration_items"
  ],
  BE_AVAILABILITY_REPORTS: [
    "role-cog-be-development",
    "role-cog-be-service-management",
    "role-sg-connect-mutation_availability_reporting",
    "role-sg-connect-query_availability_reporting"
  ],
  GROUP_MY_INSIGHTS: [
    "role-cog-be-customer-service-management",
    "role-cog-be-development",
    "role-cog-be-product-management",
    "role-cog-be-sales",
    "role-cog-be-service-management",
    "role-sg-connect-query_power_bi_reports"
  ],
  GROUP_FINANCE_PRICING_UPLOAD: [
    "role-cog-be-development",
    "role-cog-be-product-management",
    "role-sg-connect-mutation_s3_pre_signed_url"
  ],
  GROUP_SERVICE_LEVEL_REPORTS: [
    "role-cog-be-development",
    "role-cog-be-service-management",
    "role-cog-nl-service-management",
    "role-sg-connect-query_service_level_reports"
  ],
  GROUP_MSC: [
    "role-cog-be-aws",
    "role-cog-be-azure",
    "role-cog-be-customer-service-management",
    "role-cog-be-development",
    "role-cog-be-product-management",
    "role-cog-nl-aws",
    "role-cog-nl-azure",
    "role-sg-connect-query_msc"
  ],
  IO_ACCOUNTS: [
    "role-cog-be-customer-service-management",
    "role-cog-be-development",
    "role-cog-be-operations",
    "role-cog-be-sales",
    "role-cog-be-service-management",
    "role-cog-bg-operations",
    "role-cog-nl-sales",
    "role-sg-connect-mutation_dynamics365_accounts",
    "role-sg-connect-query_dynamics365_accounts"
  ],
  IO_CONTACTS: [
    "role-cog-be-customer-service-management",
    "role-cog-be-development",
    "role-cog-be-operations",
    "role-cog-be-sales",
    "role-cog-be-service-management",
    "role-cog-bg-operations",
    "role-cog-nl-sales",
    "role-sg-connect-mutation_dynamics365_contacts",
    "role-sg-connect-query_dynamics365_contacts"
  ]
};

export { AUTH_MODULE };
